@import "./variables.scss";

.app-layout {
  position: absolute;
  height: 40rem;
  border: 1px solid $appBorder;
  width: 60rem;
  min-width: 12.5rem;
  min-height: 4em;
  border-radius: 0.5rem;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  touch-action: none;
  resize: both;
  overflow: hidden;
}
.app-layout::-webkit-resizer {
  display: none;
}
.app-header-container {
  @include blur-bg-darker;
  background-color: #202028d6;
  position: relative;
  padding: 0 0.5em;
  height: 1.8em;
  width: 100%;
  touch-action: none;
  z-index: -5;
  .app-header-info {
    @include flex(center);
    height: 100%;
    width: fit-content;
    column-gap: 0.3em;
    img {
      width: 0.9em;
    }
    p {
      font-size: 0.7em;
      font-weight: 300;
      letter-spacing: 105%;
      color: $mainHeader;
    }
  }
  .app-header-control-panel {
    @include flex(center, space-between);
    position: absolute;
    inset: 0 0 auto auto;
    height: 1.8em;
    width: 8.5em;
    div {
      @include center;
      height: 100%;
      width: 33%;
      &:hover {
        background-color: rgba(211, 211, 211, 0.1);
        &:last-child {
          background-color: red;
        }
      }
      &:last-child {
        img {
          width: 0.9em;
        }
      }
      img {
        width: 0.8em;
      }
    }
  }
}
.app-layout-content {
  position: relative;
  height: calc(100% - 1.75em);
  width: 100%;
}
