@import "../../variables.scss";

.notepad-container {
  height: 100%;
  width: 100%;
  .textarea {
    height: 100%;
    width: 100%;
    background-color: #262629;
    border: none;
    color: $mainHeader;
    padding: 0.5rem;
  }
}
.notepad-header {
  @include blur-bg-darker;
  position: relative;
  height: 2.75rem;
  background-color: #202028d6;
  .notepad-header-container {
    @include flex(center);
    position: absolute;
    inset: auto auto 0.5rem 0.2rem;
    height: 1.5rem;
    column-gap: 0.25rem;
    button {
      @include hover;
      height: 2rem;
      padding: 0 0.75rem;
      background-color: transparent;
      font-size: 0.85rem;
      color: $mainHeader;
    }
  }
  .settings-icon {
    position: absolute;
    inset: auto 0.8rem 0.5rem auto;
    width: 1.1rem;
    opacity: 0.75;
  }
}
