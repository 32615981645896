@import "../../variables.scss";

.feh-container {
  @include blur-bg-darker;
  @include flex(center);
  background-color: #202028d6;
  padding: 0 0.6em;
  height: 3.75em;
  width: 100%;
  border-bottom: 1px solid rgba(122, 122, 122, 0.37);
  .horizantal-line {
    margin: 0 0.3em;
    height: 2.05em;
    width: 0.25px;
    border-radius: 4px;
    opacity: 0.1;
    background-color: $mainHeader;
  }
}
.feh-new-container {
  @include hover;
  @include flex(center);
  width: 5.15rem;
  height: 2.2em;
  padding: 0 0.5rem;
  color: $mainHeader;
  p {
    margin-left: 0.7em;
    font-size: 0.75rem;
  }
  line-height: 1.5em;
  img {
    &:first-child {
      width: 1.05em;
    }
    &:last-child {
      margin-left: 0.4em;
      width: 0.4em;
    }
  }
}
.feh-edit-toolbar-container {
  @include flex(center);
  height: 2.2em;
  width: 15rem;
  padding: 0 0.4em;
  column-gap: 1em;
  button {
    @include hover;
    background-color: transparent;
    height: 2.2rem;
    width: 2.1rem;
    img {
      width: 1.4em;
    }
    &:last-child {
      img {
        width: 1.75em;
        height: 1.3rem;
      }
    }
  }
}
.feh-view-toolbar-container {
  @include flex(center);
  padding: 0 0.25em;
  height: 2.2rem;
  column-gap: 0.6em;
  button {
    @include hover;
    @include flex(center);
    width: 4.8rem;
    height: 2.5em;
    padding: 0 0.5rem;
    color: $mainHeader;
    background-color: transparent;
    p {
      margin-left: 0.7em;
      font-size: 0.7rem;
    }
    line-height: 1.5em;
    img {
      &:first-child {
        width: 1.25em;
      }
      &:last-child {
        margin-left: 0.6em;
        width: 0.4em;
      }
    }
  }
}
.feh-more-options {
  @include hover;
  @include center;
  background-color: transparent;
  height: 2.4rem;
  width: 2.4rem;
  img {
    width: 1.25rem;
    transform: rotate(90deg);
  }
}
