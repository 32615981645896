@import "../variables.scss";

.right-panel-container {
  @include flex(center, flex-start, column);
  position: absolute;
  row-gap: 2rem;
  transition: all 500ms;
  z-index: 7;
  &.active {
    inset: auto 0.75rem 4em auto;
  }
  &.disabled {
    inset: auto -100% 4em auto;
  }
}
.clock--date-container {
  @include blur-bg-darker;
  width: 21rem;
  min-height: 23rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid $appBorder;
  transition: all 500ms ease-in-out;
  background-color: rgba(115, 115, 115, 0.257);
  &.collapsed {
    height: 3rem;
    min-height: 3rem;
    .header .header-open-up img {
      transform: rotate(180deg);
    }
  }
  .header {
    @include blur-bg-dark;
    @include flex(center, space-between);
    padding: 0 1rem;
    height: 3rem;
    color: $mainHeader;
    border-bottom: 1px solid $appBorder;
    background-color: rgba(0, 0, 0, 0.393);
    #clock-and-date-box {
      font-size: 0.8rem;
      font-weight: 300;
    }
    .header-open-up {
      @include hover;
      @include center;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid $borderColor;
      background-color: rgba(211, 211, 211, 0.1);
      img {
        width: 45%;
        transition: all 500ms;
      }
    }
  }
  .navbar-container {
    @include flex(center, space-between);
    padding-left: 1rem;
    height: 3rem;
    font-size: 0.8rem;
    color: $mainHeader;
    .arrows {
      @include flex(center);
      margin-right: 0.5rem;
      div {
        @include hover;
        padding: 0.5rem 0.75rem;
        img {
          width: 0.6rem;
          opacity: 0.55;
        }
        &:first-child {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .weekday-names-container {
    @include flex(center, space-between);
    padding: 0 1.1rem;
    margin-top: 1rem;
    height: 1rem;
    color: $mainHeader;
    font-size: 0.75rem;
  }
  .month-days-container {
    margin: 1rem auto 0.5rem auto;
    padding: 0 0.25rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.15rem;
    row-gap: 0.4rem;
    div {
      @include hover;
      @include center;
      margin: 0 0.125rem;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 0.85rem;
      color: $mainHeader;
      border-radius: 100%;
      &.active {
        color: color-contrast($mainHeader);
        background-color: $personalizedColor;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
.notifications-container {
  @include blur-bg-darker;
  width: 21rem;
  max-height: calc(100vh - 7em - 25rem);
  border-radius: 0.5rem;
  border: 1px solid $appBorder;
  background-color: rgba(115, 115, 115, 0.257);
  transition: height 500ms ease-in-out;
  overflow: hidden;
  .header {
    @include blur-bg-dark;
    @include flex(center, space-between);
    padding: 0 1rem;
    position: sticky;
    inset: 0 0 auto auto;
    font-size: 0.8rem;
    height: 3rem;
    width: 100%;
    border-bottom: 1px solid $appBorder;
    background-color: rgba(0, 0, 0, 0.393);
    color: $mainHeader;
    button {
      @include hover;
      padding: 0.4rem;
      font-weight: 100;
      font-size: 0.7rem;
      color: $mainHeader;
      background-color: rgba(211, 211, 211, 0.1);
    }
  }
  .main-container {
    @include flex(center, flex-start, column);
    width: 100%;
    .notification {
      padding: 0.75rem;
      width: 100%;
      height: 9rem;
      color: $mainHeader;
      .title {
        @include flex(center);
        img {
          width: 0.85rem;
        }
        p {
          font-size: 0.65rem;
          margin-left: 0.5rem;
        }
      }
      .time {
        margin-top: 1rem;
        font-size: 0.7rem;
        opacity: 0.7;
      }
      .details {
        @include flex(center);
        margin-top: 1rem;
        column-gap: 1rem;
        font-size: 0.8rem;
        img {
          width: 3.5rem;
        }
        div {
          @include flex(flex-start, flex-start, column);
          row-gap: 0.5rem;
          p:last-child {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
