@import "../../variables.scss";

.fe {
  position: relative;
  height: 100%;
  width: 100%;
}
.fe-container {
  @include flex(center);
  height: calc(100% - 6.25em);
  width: 100%;
  background-color: #12141b;
}
