@import "./variables.scss";

.signin-container {
  width: 100%;
  height: 100vh;
  background: url("../assets/lockscreen-background.webp");
  background-position: center;
  background-size: cover;
  text-align: center;
  user-select: none;
  overflow: hidden;
}
.signin-lockscreen {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 3;
  .signin-time {
    position: absolute;
    inset: 10rem 0 0 50%;
    transform: translateX(-50%);
    height: fit-content;
    width: fit-content;
    color: $mainHeader;
    font-family: "Roboto";
    cursor: default;
    .signin-hour {
      font-size: 3.6rem;
      font-weight: 500;
    }
    .signin-date {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  .signin-status-container {
    position: absolute;
    inset: auto 1.75rem 1.75rem auto;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
}
.signin-battery-status {
  position: relative;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  .signin-battery-back {
    position: absolute;
    inset: 50% auto auto 0.125rem;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
  }
  .signin-battery-filled-container {
    position: absolute;
    inset: 50% auto auto 0.125rem;
    transform: translateY(-50%);
    height: 1.25rem;
    overflow: hidden;
    img {
      width: 1.2rem;
      height: 1.25rem;
    }
  }
  .signin-charging-container {
    position: absolute;
    inset: 0.05rem auto auto -0.06rem;
    img {
      height: 1rem;
    }
  }
}
.signin-network-status {
  height: 1.4rem;
  width: 1.4rem;
  display: grid;
  place-items: center;
  img {
    width: 85%;
    .offline {
      margin-top: -5%;
      width: 110%;
    }
  }
}
.signin-screen {
  position: absolute;
  inset: 0 auto auto 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(25px);
  overflow: hidden;
  touch-action: none;
  .signin-screen-container {
    @include flex(center, start, column);
    position: absolute;
    inset: 12rem auto auto 50%;
    transform: translateX(-50%);
    row-gap: 0.5rem;
    div {
      height: 11.5rem;
      width: 11.5rem;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 0 0 40px $boxShadow;
      img {
        height: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
    h3 {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: $mainHeader;
    }
    button {
      margin-top: 1rem;
      width: 7.5rem;
      height: 2rem;
      color: $mainHeader;
      background-color: $boxShadow;
      border-radius: 0.25rem;
    }
  }
}
.signin-screen-panel-container {
  @include flex(center, space-between, row-reverse);
  position: absolute;
  inset: auto 1.75rem 1.75rem auto;
  height: 2rem;
  width: 10rem;
  .main-power-option-container {
    @include hover;
    @include center;
    position: relative;
    height: 2rem;
    width: 2rem;
    .power-option-button {
      @include center;
      height: 80%;
      width: 80%;
      background-color: transparent;
      img {
        width: 80%;
        transition: all 250ms;
        &:active {
          scale: 0.9;
        }
      }
    }
  }
  .accessability-container {
    @include center;
    @include hover;
    width: 2rem;
    height: 2rem;
    .accessability {
      height: 1.25rem;
      width: 1.25rem;
      background: url("../assets//icons/accessibility.png");
      background-size: contain;
      background-position: center;
    }
  }
  .signin-screen-network-status {
    @include center;
    @include hover;
    height: 2rem;
    width: 2rem;
    position: relative;
    img {
      width: 65%;
      .offline {
        margin-top: -5%;
        width: 110%;
      }
    }
  }
  .signin-screen-language-status {
    @include center;
    @include hover;
    width: 2rem;
    height: 2rem;
    color: $mainHeader;
    font-size: 0.95rem;
  }
}
