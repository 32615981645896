@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;500;700;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", "Roboto";
  overflow: hidden;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: default;
  user-select: none;
}

.icon {
  filter: invert(var(--iconColor));
}

button,
input {
  border: none;
  outline: none;
}
button {
  cursor: default;
}
