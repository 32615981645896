@import "./variables.scss";

// startMenuButton.js
.start-menu-button {
  @include center;
  @include hover;
  margin-right: 0.25rem;
  height: 3em;
  width: 3em;
  background: none;
  img {
    @include clicked;
    width: 65%;
  }
}

// startMenu.js
.start-menu-popup {
  @include blur-bg-darker;
  position: absolute;
  inset: auto auto 4em 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem;
  height: 45rem;
  width: 40rem;
  border: 1px solid $borderColor;
  z-index: 5;
  &.closed {
    transition: all 250ms ease-in;
    transform: translateY(150%) translateX(-50%);
    opacity: 0;
  }
  &.actived {
    transition: all 250ms ease-in;
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}
.start-menu-blured {
  padding: 2rem 2.25rem;
  height: 91%;
  width: 100%;
  border-bottom: 1px solid $borderColor;
  .start-menu-searchbar {
    position: relative;
    width: 100%;
    height: 2.5rem;
    border-radius: 4px;
    border-bottom: 3px solid $borderColor;
    background: rgba(19, 19, 19, 0.699);
    .start-menu-search-input {
      padding: 0 1rem 0 3rem;
      height: 100%;
      width: 100%;
      font-weight: 500;
      font-size: 0.9rem;
      color: rgb(204, 201, 201);
      background: none;
    }
    img {
      position: absolute;
      inset: 50% auto auto 1rem;
      transform: translateY(-50%) rotate(90deg);
      width: 1.1rem;
      opacity: 0.5;
    }
  }
}
.start-menu-sections {
  position: relative;
  padding-top: 0.5rem;
  margin-top: 2rem;
  height: 18rem;
  width: 100%;
  h4 {
    margin-left: 2rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: $mainHeader;
  }
  .start-menu-redirect-container {
    background: $darkBackground;
    @include flex(center, space-between);
    position: absolute;
    inset: 0.5rem 2rem auto auto;
    padding: 0 0.75rem;
    height: 1.5rem;
    column-gap: 0.75rem;
    width: fit-content;
    border-radius: 5px;
    font-size: 0.65rem;
    color: $mainHeader;
    p {
      height: fit-content;
    }
    img {
      width: 0.55rem;
    }
  }
  .start-menu-apps {
    margin-top: 1rem;
    padding-left: 0.5rem;
    height: 16.5rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .start-menu-app {
      @include flex(center, start, column);
      row-gap: 0.5rem;
      padding-top: 0.5rem;
      overflow: hidden;
      height: 30%;
      width: 16.6%;
      @include hover;
      color: $mainHeader;
      &:active {
        img {
          transform: scale(0.75);
        }
      }
      h6 {
        font-size: 0.7rem;
        font-weight: 400;
        text-align: center;
      }
      img {
        width: 35%;
      }
    }
  }
}
.start-menu-apps-recently {
  margin-top: 1rem;
  padding-left: 0.5rem;
  height: 11rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .start-menu-app-recently {
    @include flex(center, flex-start, row);
    row-gap: 0.5rem;
    padding: 1.75rem 1.5rem;
    height: 3rem;
    width: 48%;
    @include hover;
    column-gap: 1rem;
    color: $mainHeader;
    &:active {
      img {
        transform: scale(0.75);
      }
    }
    div {
      @include flex(space-between, start, column);
      row-gap: 0.25rem;
    }
    h6 {
      font-size: 0.75rem;
      font-weight: 400;
    }
    p {
      font-size: 0.65rem;
      font-weight: 400;
      opacity: 0.5;
    }
    img {
      width: 14%;
    }
  }
}

.start-menu-avatar--power-options {
  @include flex(center, space-between, row);
  padding: 0 3rem;
  height: 4rem;
  width: 100%;
  .start-menu-avatar {
    @include flex(center);
    @include hover;
    @include clicked;
    position: relative;
    padding: 0 1rem;
    height: 2.7rem;
    max-width: 15rem;
    column-gap: 0.5rem;
    div {
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
    h3 {
      color: $mainHeader;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }

  .start-menu-power-options-contianer {
    .main-power-option-container {
      @include hover;
      @include center;
      position: relative;
      height: 2.5rem;
      width: 2.5rem;
      .power-option-button {
        @include center;
        height: 80%;
        width: 80%;
        background-color: transparent;
        img {
          width: 60%;
          transition: all 250ms;
          &:active {
            scale: 0.9;
          }
        }
      }
    }
  }
}
