@import "../../variables.scss";

$mainColor: #ffffff;
$mainBC: rgb(63, 63, 63);
$searchbarBC: rgba(41, 41, 41, 0.767);
$blueActiveColor: rgb(0, 174, 255);

@mixin upper {
  position: relative;
  z-index: 2;
}

.toolbar-container {
  @include blur-bg-darker;
  position: relative;
  height: 5.25em;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    inset: 0.8em auto auto 0;
    height: calc(100% - 0.8em);
    width: 100%;
    z-index: 1;
    background-color: $mainBC;
  }
}
.toolbar-upper-container {
  @include upper;
  @include flex(center);
  padding: 0 0.25em 0 0.75em;
  top: 0.9em;
  height: 2.25em;
  width: 100%;
  button {
    height: 2em;
    width: 2em;
    @include hover;
    @include center;
    background-color: transparent;
    &.navigation-button {
      img {
        width: 1.6em;
        opacity: 0.4;
      }
      &:nth-child(2) {
        margin-left: 1.4em;
        img {
          transform: rotate(180deg);
        }
      }
      &.actived {
        img {
          opacity: 1;
        }
      }
    }
    &.refresh-button {
      margin-left: 1.25em;
      img {
        transform: translateY(180deg);
        width: 1.5em;
      }
    }
    &.star-icon {
      margin-left: 1em;
      img {
        width: 1.45em;
      }
    }
    &.star-icon {
      margin-left: 1em;
      img {
        width: 1.45em;
      }
    }
    &.sync-button {
      @include flex(center, space-between);
      padding-left: 0.75em;
      padding-right: 0.35em;
      margin-left: 1.1em;
      height: 2.5em;
      width: 9em;
      font-weight: 300;
      font-size: 0.8em;
      color: $mainColor;
      border-radius: 20px;
      border: 1px solid $mainColor;
      img {
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;
      }
    }
    &.menu-button {
      width: 3.25em;
      height: 2.25em;
      margin-left: 0.25em;
      img {
        width: 1.25em;

        transform: rotate(90deg);
      }
    }
  }
  form {
    @include flex(center);
    position: relative;
    border-radius: 4px;
    margin-left: 1em;
    height: 2em;
    width: 55%;
    flex-grow: 1;
    border: 2px solid $blueActiveColor;
    background-color: $searchbarBC;
    .edge-search-icon {
      margin-left: 1em;
      width: 1.1em;
    }
    input {
      background-color: transparent;
      height: 2em;
      font-size: 0.9em;
      font-weight: 200;
      margin-left: 1em;
      color: $mainColor;
      flex-grow: 0.7;
      width: 60%;
    }
    button {
      position: absolute;
      inset: 50% 0.75em auto auto;
      transform: translateY(-50%);
      margin-left: 1.75em;
      height: 2.2em;
      width: 2.2em;
      @include center;
      img {
        width: 1.4em;
        opacity: 0.4;
        &:nth-child(1) {
          position: absolute;
          inset: 50%;
          transform: translate(-50%, -50%);
        }
        &:nth-child(2) {
          width: 0.8em;
          position: absolute;
          inset: 70% 0 0 70%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.stared-websites {
  @include upper;
  @include flex(center);
  padding: 0 0.25em 0 0.5em;
  margin-top: 1em;
  width: 100%;
  height: 2em;
  column-gap: 0.5rem;
  overflow: hidden;
  button {
    @include flex(center);
    @include hover;
    padding-left: 0.25em;
    padding-right: 0.25em;
    height: 1.75rem;
    width: fit-content;
    column-gap: 0.5rem;
    font-size: 1em;
    font-weight: 100;
    color: $mainColor;
    background-color: transparent;
    img {
      height: 1.1em;
      width: 1.1em;
      border-radius: 50%;
    }
  }
}
