.main {
  position: absolute;
  background: url("../assets/main-background.webp");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
}
.main-runningApp {
  position: fixed;
  height: 100vh;
  width: 100%;
}
