@import "../../variables.scss";

.fe-sidebar {
  margin-top: -1.25%;
  padding: 0.25rem 0.1rem;
  height: 95%;
  width: 12rem;
  border-right: 1px solid rgba(122, 122, 122, 0.37);
}
.sidebar-menu-section {
  @include flex(flex-start, flex-start, column);
  padding: 0.5rem 0.3rem;
  height: fit-content;
  width: 100%;
  .sidebar-menu-header {
    @include flex(center);
    max-width: 90%;
    img {
      &:first-child {
        width: 0.6rem;
        transform: rotate(-90deg);
        &.extended {
          transform: none;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      &.folder-icon {
        width: 0.975rem;
        margin-left: 0.6rem;
      }
    }
    p {
      margin-left: 0.25rem;
      height: 0.75rem;
      max-width: 6rem;
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 300;
      overflow: hidden;
      color: $mainHeader;
      word-break: break-all;
    }
  }
  .sidebar-menu-children {
    width: 100%;
    margin-top: 0.1rem;
    .sidebar-menu-item {
      @include flex(center);
      @include hover;
      padding: 0.125rem 0 0.125rem 1.8rem;
      margin-top: 0.4rem;
      width: 100%;
      img {
        width: 1rem;
      }
      p {
        margin-left: 0.4rem;
        height: 0.73rem;
        max-width: 6rem;
        font-size: 0.73rem;
        line-height: 0.73rem;
        font-weight: 300;
        overflow: hidden;
        color: $mainHeader;
        word-break: break-all;
      }
    }
  }
}
