@import "../../variables.scss";

.mail-container {
  background: url("../../../assets/gif/sky.gif");
  background-position: top;
  background-size: cover;
  height: 110%;
  padding-top: -5%;
  width: 110%;
}
