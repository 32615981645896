@import "../../../styles/variables.scss";

$sepratorColor: rgb(139, 139, 139);
$hoverColor: rgb(63, 63, 63, 0.4);
$mainBCColor: rgb(63, 63, 63);
$textColor: #ffffff;

.tabs-container {
  @include flex(center);
  position: absolute;
  inset: -1.6em auto auto 0;
  z-index: 5;
  height: 2.4em;
  max-width: 82%;
  .sidebar-settings-icon {
    @include center;
    height: 1.8em;
    width: 2.8em;
    img {
      width: 1em;
    }
  }
  .tabs-main-container {
    @include flex(flex-end);
    position: relative;
    height: 100%;
    max-width: 90%;
    overflow: hidden;
    .tab-container {
      @include flex(center);
      padding: 0.4em 0.6em 0.7em 0.6em;
      position: relative;
      width: 15rem;
      height: 2.2em;
      min-width: 4rem;
      flex-grow: 1;
      column-gap: 0.55em;
      border-radius: 4px 4px 0 0;
      transition: background-color 300ms;
      img {
        width: 1.1em;
      }
      h3 {
        font-weight: 300;
        font-size: 0.75em;
        overflow: hidden;
        height: 1.5em;
        color: $textColor;
        max-width: 40%;
        overflow: hidden;
      }
      &:hover {
        background-color: $hoverColor;
        .close-tab-button {
          @include center;
          @include hover;
          margin-left: 0.4em;
          position: absolute;
          inset: auto 0.7em auto auto;
          z-index: 2;
          width: 1.4em;
          height: 1.4em;
          background-color: transparent;
          img {
            width: 1em;
            transform: rotate(45deg);
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        inset: 15% 0 auto auto;
        height: 60%;
        width: 0.01em;
        border-radius: 10px;
        background-color: $sepratorColor;
      }
      &.active {
        background-color: $mainBCColor;
        .close-tab-button {
          @include center;
          @include hover;
          margin-left: 0.4em;
          position: absolute;
          inset: auto 0.7em auto auto;
          height: 1.4em;
          width: 1.4em;
          z-index: 2;
          background-color: transparent;
          img {
            width: 1em;
            transform: rotate(45deg);
          }
        }
        &::before {
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          inset: 0 0 auto auto;
          height: 100%;
          width: 2.5em;
          z-index: 1;
          background: linear-gradient(270deg, $mainBCColor 60%, transparent 100%);
          border-top-right-radius: 4px;
        }
      }
    }
  }
  .close-tab-button {
    display: none;
  }
  .new-tab-button {
    @include center;
    @include hover;
    margin-left: 0.4em;
    height: 2em;
    width: 2em;
    background-color: transparent;
    img {
      width: 1.2em;
    }
  }
}
