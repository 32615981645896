@import "./variables.scss";

.shutdown-container {
  @include center;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  .shut-down-contents {
    @include center;
    img {
      width: 8rem;
    }
    p {
      margin-top: 1rem;
      font-size: 1.5rem;
      text-align: center;
      color: #fff;
    }
  }
}
