@import "./variables.scss";

.desktop-container {
  height: calc(100vh - 3em);
  padding: 0.25rem;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  gap: 0.5rem 1rem;
  .app {
    @include flex(center, start, column);
    height: 5.8rem;
    width: 4.5rem;
    overflow: hidden;
    &.activate {
      @include blur-bg;
      overflow: visible;
    }
    img {
      margin-top: 1rem;
      width: 2.5rem;
    }
    span {
      margin-top: 0.5rem;
      max-width: 100%;
      height: 2rem;
      font-size: 0.7rem;
      text-align: center;
      color: $mainHeader;
      transition: all 500ms;
    }
  }
}
